import React from 'react';
import Navigation from '../layout/Navigation';
import Footer from '../layout/Footer';
// import server from "../config/server";
// import useStateConfig from "../hooks/useStateConfig";

// const API = server.baseURL + "/paginas/configuraciones";
const Layouts = ({children}) => {
    // const products = useStateConfig(API);
    // const dispatch = useDispatch();
    
    return (
    <React.Fragment>
        <div className="main-container">
        <Navigation />
        
        {children}
        
        <Footer />
        </div>

     
    </React.Fragment>

    )
};
export default Layouts;

import styled from 'styled-components'

const StyledSolidButton = styled.button `
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #174D03;
  border: rgb(196, 184, 184);
  color: white;
  width: 20rem;
  height: 3.5rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }

  ${props => props.type === 'primary'&&`

  background-color:  transparent;
  color: black;
  border: solid 1px grey;

  `}
  ${props => props.type === 'primary22'&&`

  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #174D03;
  border: rgb(196, 184, 184);
  color: white;
  width: 20rem;
  height: 3.5rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }


  `}
  ${props => props.type === 'primary22'&&`

  background-color: #174D03;
  width: 32rem;
  height: 4rem;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  @media (max-width:900px) {
    width: 23rem;
  }



  `}
  ${props => props.type === 'primary22-cancelar'&&`

  background-color: transparent;
  color: white;
  border: solid 2px #174D03;
  width: 32rem;
  height: 4rem;
  margin-top:-1rem;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  @media (max-width:900px) {
    width: 23rem;
  }



  `}
  ${props => props.type === 'secondary'&&`

  background-color: #174D03;
  width: 32rem;
  height: 4rem;
  @media (min-width:860px) {
    background-color: #174D03;
    width: 40rem;
    height: 4rem;
  }
  `}

  ${props => props.type === 'tertiary'&&`

  background-color: transparent;
  width: 32rem;
  height: 4rem;
  color: white;
  margin-top:0;
  border: solid 2px #174D03;
  @media (min-width:860px) {
 
    width: 40rem;
    height: 4rem;
  }
  `}
  ${props => props.type === 'tertiary1'&&`

  font-size: calc(16px + 6 * ((10vw - 320px) / 680));

    width: 13rem;
    height: 3.5rem;
    margin: 1rem;
    font-family: 'Roboto',sans-serif;
    font-weight: bold;
    background-color: transparent;
    width: 15rem;
    height: 4rem;
    color: white;
    margin-top: 0;
    border: solid 2px #174D03;
  @media (min-width:860px) {
 
    width: 40rem;
    height: 4rem;
  }
  `}
  ${props => props.type === 'red-agregar-c'&&`

  font-size: 1.5rem;
  background-color:  #eac315;
  border: rgb(196, 184, 184);
  color: white;
  width: 13rem;
  height: 4rem;
  margin: 2rem;
  border-radius: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  `}
  ${props => props.type === 'amarillo-compra'&&`

  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: transparent;
  border: rgb(196, 184, 184);
  color: black;
  width: 13rem;
  height: 3.5rem;
  margin: 2rem;
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  border: solid 1px grey;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  `}
  ${props => props.type === 'amarillo-compra-productos-p'&&`

  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: transparent;
  border: rgb(196, 184, 184);
  color: white;
  width: 15rem;
  height: 3.5rem;
  margin: 2rem;
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  border: solid 1px grey;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  `}

  ${props => props.type === 'agregar'&&`
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #174D03;
  border: rgb(196, 184, 184);
  color: white;
  width: 13rem;
  height: 3.5rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  @media (min-width:700px) {
    font-size: calc(16px + 6 * ((5vw - 320px) / 680));
  }
  `}
  ${props => props.type === 'agregar-productos-p'&&`
  font-size: calc(16px + 6 * ((5vw - 320px) / 680));
  background-color: #174D03;
  border: rgb(196, 184, 184);
  color: white;
  width: 15rem;
  height: 4rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  @media (min-width:700px) {
    font-size: calc(16px + 6 * ((5vw - 320px) / 680));
  }
  `}
  

`;
export default StyledSolidButton;
import axios from 'axios';
import server from "./server"

const clienteAxios = axios.create({
    baseURL: server.baseURL,
    params: {
        t: new Date().getTime()
      }
});
// const http = axios.create({
// 	baseURL: '/',
// 	headers: { 'Cache-Control': 'no-cache' },
// 	adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))
// });
export default clienteAxios;
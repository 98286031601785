import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
//import { productsReducer,productReviewSaveReducer,} from "./reducers/productReducer";
import { cartReducer } from './reducer/CartReducer';
import { productsReducer} from './reducer/ProductReducer';
import { productsReducer1} from './reducer/ProductReducer1';
import { productsReducer2} from './reducer/ProductReducer2';
import { productsReducer3} from './reducer/ProductReducer3';
import { productsReducerPrem} from './reducer/ProductReducerPrem';
import {userSigninReducer,userDetailsReducer,userRecuperarReducer,userUpdateProfileReducer,userRegisterReducer} from './reducer/UserReducer'
import {  orderCreateReducer,orderDetailsReducer} from './reducer/OrderReducer';
import {votacionesRegisterReducer,projectsReducer} from './reducer/ProjectReducer';
import {configServerReducer} from './reducer/ConfigReducer';

  
let userInfo 
try {
  
   userInfo = JSON.parse(localStorage.getItem('userInfo'));
} catch (error) {
   userInfo =  null;
}
// let configServer 
// try {
  
//   configServer = JSON.parse(localStorage.getItem('configServer'));
// } catch (error) {
//   configServer =  null;
// }

  const initialState = {
     userSignin: { userInfo },
    // configServer:{ configServer },
    // userSignin: {
    //   userInfo: localStorage.getItem('userInfo')
    //     ? JSON.parse(localStorage.getItem('userInfo'))
    //     : null,
    // },
    cart: {
      cartItems: localStorage.getItem('cartItems')
        ? JSON.parse(localStorage.getItem('cartItems'))
        : [],
        // facturacion: localStorage.getItem('facturacion')
        // ? JSON.parse(localStorage.getItem('facturacion'))
        // : {},

    },
  };
const reducer = combineReducers({

  cart:cartReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  products: productsReducer,
  auspiciantes:productsReducer1,
  auspiciantes2:productsReducer2,
  auspiciantes3:productsReducer3,
  auspiciantesPrem:productsReducerPrem,
  userDetails: userDetailsReducer,
  userRecuperar:userRecuperarReducer,
  userUpdateProfile: userUpdateProfileReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  votacionesRegister:votacionesRegisterReducer,
  projects:projectsReducer,
  configServer1:configServerReducer,



 

});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
import React from 'react';
import "../App.css";
import Zoom from 'react-reveal/Zoom';





function Footer() {

  return (
    <div className="footer">

      
      <Zoom bottom>
      <div className="footer-redes">


      

<div className="whatsapp-c">

<p className=" p2">Desarrollado por</p>
</div>
</div>
<div className="footer-derechos">
<b className="p2" style={{marginBottom:'1rem',marginTop:'-1rem'}}>Emprendimientos Virtuales S.A.</b>
</div>
        </Zoom>
     
      
    </div>
  );
}

export default Footer;

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';
export const PRODUCT_REVIEW_SAVE_REQUEST = 'PRODUCT_REVIEW_SAVE_REQUEST';
export const PRODUCT_REVIEW_SAVE_SUCCESS = 'PRODUCT_REVIEW_SAVE_SUCCESS';
export const PRODUCT_REVIEW_SAVE_FAIL = 'PRODUCT_REVIEW_SAVE_FAIL';
export const PRODUCT_REVIEW_SAVE_RESET = 'PRODUCT_REVIEW_SAVE_RESET';
export const PRODUCT_DETAILS_REQUEST ='PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS ='PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL ='PRODUCT_DETAILS_FAIL';
export const FILTER_PRODUCTS_BY_CATEGORY2 = "FILTER_PRODUCTS_BY_CATEGORY2";
export const PRODUCT_DONADOR_SAVE_RESET = 'PRODUCT_DONADOR_SAVE_RESET';
export const FILTER_PRODUCTS_BY_CATEGORY ='FILTER_PRODUCTS_BY_CATEGORY';
export const ORDER_PRODUCTS_BY_PRICE ='ORDER_PRODUCTS_BY_PRICE';
export const FETCH_PRODUCTS ='FETCH_PRODUCTS';
export const FETCH_PRODUCTS1='FETCH_PRODUCTS1';
export const FETCH_PRODUCTS2='FETCH_PRODUCTS2';
export const FETCH_PRODUCTS3='FETCH_PRODUCTS3';
export const FETCH_PRODUCTS_PREM='FETCH_PRODUCTS_PREM';

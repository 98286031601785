import React from "react";
import { useSelector } from "react-redux";
import "../App.css";
import MenuUser from "../userBotones/MenuUser";

function BotonUser2(props) {
  // const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  return (
    <React.Fragment>
      {userInfo ? (
        <div>
          <MenuUser />
        </div>
      ) : (
<React.Fragment/>
      )}
    </React.Fragment>
  );
}

export default BotonUser2;

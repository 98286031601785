
  import {
    FILTER_PRODUCTS_BY_CATEGORY2,
    FETCH_PRODUCTS3,
    FILTER_PRODUCTS_BY_CATEGORY,
    ORDER_PRODUCTS_BY_PRICE,
  } from '../constants/ProductType';
  
  export const productsReducer3 = (state = {}, action) => {
    switch (action.type) {
      case FILTER_PRODUCTS_BY_CATEGORY:
        return {
          ...state,
          id_categoria: action.payload.id_categoria,
          filteredItems: action.payload.items,
        };
        case FILTER_PRODUCTS_BY_CATEGORY2:
          return {
            ...state,
            name: action.payload.name,
            filteredItems: action.payload.items,
          };
      case ORDER_PRODUCTS_BY_PRICE:
        return {
          ...state,
          sort: action.payload.sort,
          filteredItems: action.payload.items,
        };
      case FETCH_PRODUCTS3:
        return { items: action.payload, filteredItems: action.payload };
  
  
      default:
        return state;
    }
  };

  
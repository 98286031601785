import {CONFIG_REQUEST,CONFIG_SUCCESS,CONFIG_FAIL } from "../constants/ConfigType";


function configServerReducer(state = {}, action) {
  
    switch (action.type) {
      case CONFIG_REQUEST:
        return { loading: true };
      case CONFIG_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case CONFIG_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  export {configServerReducer};
import React from "react";
import '../App.css';
import Sidebar2 from "./Sidebar2";
import imgcancelar from "../assets/imagenes/fans-luque/generales/cancelar.png"




class Sidebar extends React.Component {

  
    render () {
      const closeMenu = () => {
        document.querySelector(".sidebar").classList.remove("open");
      };

      return (<div>
       
          <div className="contenedor-nav-sidebar-22">
            
            
            <button className="icono-button" onClick={closeMenu}>
            <img
                  src={imgcancelar}
                  className="cancelar"
                  alt="cancelar"
                />
              </button>

             
            
             
            

            

          </div>
  
        <div >
<Sidebar2/>
        

        
        </div>
        </div>

       
      );
    }
  }
  export default Sidebar;


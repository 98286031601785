
  import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_REVIEW_SAVE_SUCCESS,
    PRODUCT_REVIEW_SAVE_REQUEST,
    PRODUCT_REVIEW_SAVE_FAIL,
    PRODUCT_REVIEW_SAVE_RESET,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    FILTER_PRODUCTS_BY_CATEGORY2,
    FETCH_PRODUCTS,
    FILTER_PRODUCTS_BY_CATEGORY,
    ORDER_PRODUCTS_BY_PRICE,
  } from '../constants/ProductType';
  
  export const productsReducer = (state = {}, action) => {
    switch (action.type) {
      case FILTER_PRODUCTS_BY_CATEGORY:
        return {
          ...state,
          id_categoria: action.payload.id_categoria,
          filteredItems: action.payload.items,
        };
        case FILTER_PRODUCTS_BY_CATEGORY2:
          return {
            ...state,
            name: action.payload.name,
            filteredItems: action.payload.items,
          };
      case ORDER_PRODUCTS_BY_PRICE:
        return {
          ...state,
          sort: action.payload.sort,
          filteredItems: action.payload.items,
        };
      case FETCH_PRODUCTS:
        return { items: action.payload, filteredItems: action.payload };
  
  
      default:
        return state;
    }
  };
  
  export const productListReducer =(state = { products: [] }, action) => {
    switch (action.type) {
      case PRODUCT_LIST_REQUEST:
        return { loading: true, products: [] };
      case PRODUCT_LIST_SUCCESS:
        return { loading: false, products: action.payload };
      case PRODUCT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
  export const productReviewSaveReducer = (state = {}, action) => {
    switch (action.type) {
      case PRODUCT_REVIEW_SAVE_REQUEST:
        return { loading: true };
      case PRODUCT_REVIEW_SAVE_SUCCESS:
        return { loading: false, review: action.payload, success: true };
      case PRODUCT_REVIEW_SAVE_FAIL:
        return { loading: false, errror: action.payload };
      case PRODUCT_REVIEW_SAVE_RESET:
        return {};
      default:
        return state;
    }
  }
  export const productDetailsReducer = (state = { product: { reviews: [] } }, action)=> {
    switch (action.type) {
      case PRODUCT_DETAILS_REQUEST:
        return { loading: true };
      case PRODUCT_DETAILS_SUCCESS:
        return { loading: false, product: action.payload };
      case PRODUCT_DETAILS_FAIL:
        return { loading: false, error: action.payload }
       
      default:
        return state;
    }
  }
  
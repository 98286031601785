import React from 'react';
import StyledSolidButton from './StyledSolidButton';

const SolidButton = ({onClick,props,txt,type,img}) => {
    return (
<React.Fragment>
    <StyledSolidButton onClick={onClick} {...props} type={type}>{txt}</StyledSolidButton>
</React.Fragment>
    )
}
export default SolidButton;
import {FETCH_PROJECTS,REGISTER_REPRESENTANTE_REQUEST, REGISTER_REPRESENTANTE_SUCCESS, REGISTER_REPRESENTANTE_FAIL,} from "../constants/ProjectType";





export const projectsReducer = (state = {}, action) => {
    switch (action.type) {
  

  
      case FETCH_PROJECTS:
        return { items: action.payload, filteredItems: action.payload};
        // case CART_SAVE_VOLUNTARIADO:
        // return { ...state, voluntariado: action.payload };
      default:
        return state;
    }
    
  };

  
  export const votacionesRegisterReducer = (state = {}, action) => {
    switch (action.type) {
      case REGISTER_REPRESENTANTE_REQUEST:
        return { loading: true };
      case REGISTER_REPRESENTANTE_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case REGISTER_REPRESENTANTE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }